<template>
  <main>
    <trac-loading v-if="loading" />
    <trac-modal v-if="successful" :cancel="false">
      <div class="flex flex-col justify-center items-center">
        <img
          src="@/assets/svg/success.svg"
          class="w-32 h-32 my-6 bg-white"
          alt=""
        />

        <p class="text-center tracking-normal w-2/3 text-xs mb-6 -mt-2 px-8">
          {{ message }}
        </p>

        <button
          @click="$router.push({ name: 'KycProfile' })"
          class="bg-none border-none text-primaryBlue text-xs outline-none font-semibold"
        >
          Back to KYC
        </button>
      </div>
    </trac-modal>
    <trac-back-button :backAction="backAction"> Back </trac-back-button>
    <p class="text-base text-gray-800 font-semibold mt-5 ml-3">
      Complete KYC by verifying the following.
    </p>
    <!-- What's class to horizontally center a flex element? -->
    <div
      class="w-full big-shadow bg-white mt-12 p-12 flex flex-col justify-center"
    >
      <div class="px-5 rounded-md flex-1 text-center">
        <img class="inline-block" src="@/assets/svg/document-icon.svg" alt="" />
      </div>
      <div class="text-center">
        <h2 class="text-lg font-extrabold text-blue-800">
          Add your Company Documents
        </h2>
      </div>
      <div class="w-2/3 sm:w-full md:w-3/5 lg:w-2/5 mx-auto py-8">
        <trac-dropdown
          :title="'Business Registration Type'"
          class="w-full mt-6"
          v-model="registrationType"
          :menuItems="registrationOptions"
        ></trac-dropdown>
        <trac-input
          class="mt-6"
          v-model="RCBNNumber"
          :placeholder="'RC/BN Number'"
        ></trac-input>
        <trac-input
          class="mt-6"
          v-model="LegalName"
          :placeholder="'Legal Name'"
        ></trac-input>
        <trac-upload
          :placeholder-text="'Upload CAC CERTIFICATE'"
          :view="'documentInput'"
          @uploadedFile="getFileUrl($event, 'cacCertificateUrl')"
          :allowed-types="['image/png', 'image/jpeg', 'application/pdf']"
        />
        <trac-upload
          :placeholder-text="'Upload Certified Application Form'"
          :view="'documentInput'"
          @uploadedFile="getFileUrl($event, 'certifiedApplicationUrl')"
          :allowed-types="['image/png', 'image/jpeg', 'application/pdf']"
        />
        <trac-upload
          :placeholder-text="'Upload DIRECTOR ID (Optional)'"
          :view="'documentInput'"
          @uploadedFile="getFileUrl($event, 'directorIdUrl')"
          :allowed-types="['image/png', 'image/jpeg', 'application/pdf']"
        />
        <trac-input
          class="mt-6"
          v-model="TaxNumber"
          :placeholder="'Tax Identification Number(TIN) (Optional)'"
        ></trac-input>
        <div class="text-right mt-6">
          <trac-button
            :disabled="!formCheck.valid"
            @button-clicked="sendDetails()"
            class="uppercase"
            >Continue</trac-button
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "../../eventBus";
export default {
  data() {
    return {
      loading: false,
      RCBNNumber: "",
      LegalName: "",
      TaxNumber:"",
      registrationOptions: [],
      message: "",
      registrationType: "",
      isCustomKycRoute: false,
      email: "",
      utilityBillUrl: "",
      address: "",
      cacCertificateUrl: "",
      certifiedApplicationUrl: "",
      directorIdUrl: "",
      latlong: {
        latitude: 0,
        longitude: 0,
      },
      successful: false,
    };
  },
  mounted() {
    const user = GET_USER_BUSINESS_DATA();
    this.email = user.email;
    this.isCustomKycRoute = this.$route.query.custom || false;
  },
  created () {
    this.$store.dispatch('GET_BUSINESS_REGISTRATION_TYPES').then((res) => {
      if (res.status) {
        this.registrationOptions = res.data.map((item) => {
          let name
          if(item.key === 'bn') {name = "Business Name"}
          if(item.key === 'co') {name = "Limited liability Company"}
          if(item.key === 'it') {name = "Incorporated Trustees eg. NGOs, Religious Organizations"}
          return {
            name,
            value: item.key,
          };
        });
      }
    });
  },
  methods: {
    getAddressData(data) {
      console.log("data from autocomplete, ", data);
      this.address = data.route;
    },
    backAction() {
      this.$router.replace({
        name: "KycProfile",
        params: { custom: this.isCustomKycRoute },
      });
    },
    getFileUrl(url, dataProperty) {
      console.log(`url for ${dataProperty}`, url);
      this.$set(this, dataProperty, url);
    },
    async sendDetails() {
      this.loading = true;
      this.$store.dispatch("PROCESS_CORPORATE_VERIFICATION", this.payload).then((res) => {
        if (res.status) {
          this.successful = true;
          this.loading = false;
          this.message = res.message;
        } else {
          this.successful = false;
          eventBus.$emit("trac-alert", {
            message: res.message || "An error occurred",
          });
          this.loading = false;
        }
      });
    },
  },
  computed: {
    selectedRegistrationType() {
      const option = this.registrationOptions.find(
        (item) => item.name === this.registrationType
      )
        return option ? option.value : ''
    },
    formCheck() {
      const list = {
        cacCertificateUrl:
          this.cacCertificateUrl.length > 0 &&
          typeof this.cacCertificateUrl === "string",
        registrationType:
          this.registrationType.length > 0 &&
          typeof this.registrationType === "string",
        RCBNNumber:
          this.RCBNNumber.length > 0 && typeof this.RCBNNumber === "string",
        certifiedApplicationUrl:
          this.certifiedApplicationUrl.length > 0 &&
          typeof this.certifiedApplicationUrl === "string",
        selectedRegistrationType: 
          this.selectedRegistrationType.length > 0 && 
          typeof this.selectedRegistrationType === "string",
      };
      if (this.directorIdUrl) {
        list.directorIdUrl =
          this.directorIdUrl.length > 0 &&
          typeof this.directorIdUrl === "string";
      }
      if (this.TaxNumber) {
        list.TaxNumber =
          this.TaxNumber.length > 0 && typeof this.TaxNumber === "string";
      }
      return {
        valid: Object.values(list).every((item) => item === true),
        rules: list,
      };
    },
    payload() {
      const payload = {
        idType: this.selectedRegistrationType,
        idNumber: this.RCBNNumber,
        legal_name: this.LegalName,
        cac_document_url:
          this.cacCertificateUrl,
        certified_application_form:
          this.certifiedApplicationUrl
      };
      if(this.directorIdUrl) {payload.director_id = this.directorIdUrl}
      if(this.TaxNumber) {payload.tax_id_number = this.TaxNumber}
      return payload
    },
  },
};
</script>